/* Disabled @tailwind base because this is conflicting with antd reset. */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Add Mont Family Font */
@font-face {
  font-family: Mont-Regular;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("/assets/fonts/mont/mont-regular.otf");
}

@font-face {
  font-family: Mont-Regular;
  font-style: semibold;
  font-weight: 600;
  font-display: swap;
  src: url("/assets/fonts/mont/mont-semibold.otf");
}

@font-face {
  font-family: Mont-Regular;
  font-style: bold;
  font-weight: 700;
  font-display: swap;
  src: url("/assets/fonts/mont/mont-bold.otf");
}

:root {
  --accent-yellow-bright: #fff566;
  --accent-green-dark: #29ca9a;
  --accent-blue-light: #5dbdf3;
  --accent-orange-light: #fb9d38;
  --accent-yellow-light: #ffe8ae;
  --accent-magenta: #f342d7;
  --accent-purple: #948bff;
  --accent-yellow-sunrise: #fadb14;
  --accent-teal: #2bc8d8;
  --accent-yellow: #f3c342;

  --neutral-grey-dark: #b8bbc1;
  --neutral-grey: #f0f1f9;
  --neutral-grey-light: #f4f6fc;
  --neutral-grey-mid: #e8ebf1;
  --neutral-grey-quartz: #eceef8;
  --neutral-grey-surface: #fafbff;
  --neutral-white: #ffffff;

  --overlay-black: #000000;
  --overlay-silver-light: #d2d4dd;

  --semantic-blue: #1c7cff;
  --semantic-green: #64dd17;
  --semantic-orange: #f49c00;
  --semantic-red: #f6323e;
  --semantic-yellow: #f3c342;

  --tpl-blue: #5b9dff;
  --tpl-blue-dark: #4284e7;
  --tpl-blue-light: #adceff;
  --tpl-navy: #4f537b;
  --tpl-navy-dark: #3a3e61;
  --tpl-navy-light: #a7a9bd;
  --tpl-red: #f36b7f;
  --tpl-red-dark: #ea455d;
  --tpl-red-light: #f9b5bf;
}

html {
  font-family: "Mont-Regular", "-apple-system", "BlinkMacSystemFont",
    "Segoe UI", "Roboto", "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
